<template>
  <div>
    <stories-slider />
  </div>
</template>
<script>
/* eslint-disable vue/max-len */
import {directive} from 'vue-awesome-swiper';
import {mapGetters, mapMutations} from 'vuex';
// import StoriesSlidesDesktop from '~/components/catalog/storiesSlidesDesktop';
// import StoriesSlidesMobile from '~/components/catalog/storiesSlidesMobile';
import StoriesSlider from '~/components/catalog/storiesSlider/index.vue';

export default {
  name: 'Stories',
  components: {StoriesSlider},
  directives: {
    swiper: directive,
  },
  data() {
    return {
      sliderOptions: {
        touchRatio: 1,
        observer: true,
        observeParents: true,
        spaceBetween: 0,
        slidesPerView: 'auto',
        centeredSlides: false,
        loop: true,
        watchSlidesVisibility: false,
        watchSlidesProgress: false,
        resistance: true,
        resistanceRatio: 0.7,
        preloadImages: false,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        lazy: {
          loadOnTransitionStart: false,
          loadPrevNext: true,
        },
      },
      storiesThumbnailsSliderOptions: {
        touchRatio: 1,
        observer: true,
        observeParents: true,
        spaceBetween: 0,
        slidesPerView: 'auto',
        centeredSlides: true,
        loop: true,
        watchSlidesVisibility: false,
        watchSlidesProgress: false,
        resistance: true,
        resistanceRatio: 0.7,
        preloadImages: false,
        autoplay: {
          delay: 1000,
          disableOnInteraction: true,
        },
        lazy: {
          loadOnTransitionStart: false,
          loadPrevNext: true,
        },
      },
      storiesThumbnailsSliderOptionsMobile: {
        touchRatio: 1,
        observer: true,
        observeParents: true,
        slidesPerView: 'auto',
        centeredSlides: false,
        loop: false,
        watchSlidesVisibility: false,
        watchSlidesProgress: false,
        resistance: false,
        // resistanceRatio: 0.7,
        preloadImages: false,
        freeMode: true,
      },
    };
  },
  computed: {
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile';
    },
    ...mapGetters('stories', {
      items: 'getItems',
      storyOpen: 'getIsOpen',
      initialIndex: 'getInitialIndex',
    }),
  },
  methods: {
    ...mapMutations('stories', {
      setIsStoryOpen: 'SET_IS_OPEN',
      setInitialIndex: 'SET_INITIAL_INDEX',
      setCurrentSlideIndex: 'SET_CURRENT_SLIDE',
    }),
    handleOpenStory(index) {
      this.setIsStoryOpen(true);
      this.setInitialIndex(index);
      this.setCurrentSlideIndex(0);
    },
    handleCloseStory() {
      this.setIsStoryOpen(false);
    },
  },
}
</script>
<style scoped>
.stories {
  position: relative;
  width: 100%;
  padding-top: 40px;
  display: flex;
  justify-content: center;
  .back-line {
    position: absolute;
    height: 62px;
    background-color: #FBF6F4;
    width: 100vw;
  }
  .story-item {
    text-align: center;
    z-index: 1;
    .story-item-image {
      border: 2px solid #BB032A;
      cursor: pointer;
      .thumbnail-img {
        background-size: cover;
        background-repeat: inherit;
        background-position: center;
      }
    }
    .story-item-title {
      color: var(--color-basic-black);
      text-align: center;
      max-width: 120px;
    }
  }
}
@media all and (min-width: 576px) {
  .back-line {
    top: 68px;
  }
  .story-item {
    width: 120px;
    .story-item-image {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      padding: 8px;
      .thumbnail-img {
        height: 80px;
        width: 80px;
        border-radius: 40px;
      }
    }
  }
}
@media all and (max-width: 575px) {
  .back-line {
    top: 39px;
  }
  .swiper-wrapper {
    display: flex;
    .story-item {
      width: 76px;
      padding-left: 8px;
      padding-right: 8px;
      .story-item-image {
        height: 58px;
        width: 58px;
        border-radius: 50%;
        display: flex;
        .thumbnail-img {
          height: 48px;
          width: 48px;
          border-radius: 24px;
        }
      }
    }
  }
}
.stories-popup {
  /*position: fixed;*/
  /*z-index: 2;*/
  /*top: 0;*/
  /*left: 0;*/
  /*height: 100vh;*/
  background-color: rgba(0,0,0,0.3);
  backdrop-filter: blur(4px);
  transition: opacity .3s ease-in-out,visibility .3s ease-in-out;
}
/*.stories-slides {*/
/*  display: flex;*/
/*}*/
.stories-slide {
  /*width: 200px;*/
}
.story-0, .story-2 {
  .story-content {
    transform: scale(0.9);
  }
}
.story-1 {
  .story-content {
    transform: scale(1) !important;
  }
}
@media all and (min-width: 1024px) {
  .stories-mobile {
    display: none;
  }
}
@media all and (max-width: 1023px) {
  .stories-desktop {
    display: none;
  }
}
</style>
